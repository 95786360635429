
import Vue from "vue";

export default Vue.extend({
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    webpSrc(): string {
      return this.tag.image.replace(
        /\/tour_tags\/thumb_/,
        "/tour_tags/thumbp_"
      );
    },
    tagName(): string {
      if (!this.tag) {
        return "";
      }
      if (this.$i18n.locale === "ja") {
        return this.tag.ja;
      } else if (this.$i18n.locale === "zh-Hans") {
        return this.tag["zh-Hans"];
      }
      return this.tag.en;
    },
  },
});
