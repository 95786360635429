
import Vue from "vue";

export default Vue.extend({
  data() {
    const tags: Peeeps.Data.TourTags[] = [];
    return {
      loading: true,
      tags,
    };
  },
  async mounted() {
    const tagsCol = await this.$fire.firestore
      .collection("tour_tags")
      .orderBy("updated_at", "desc")
      .limit(10)
      .get()
      .catch((err) => {
        this.$sentry.captureException(err);
      })
      .finally(() => {
        this.loading = false;
      });
    if (!tagsCol) {
      return;
    }
    tagsCol.forEach((tagDoc) => {
      if (tagDoc.get("image")) {
        this.tags.push({
          id: tagDoc.id,
          en: tagDoc.get("en"),
          ja: tagDoc.get("ja"),
          "zh-Hans": tagDoc.get("zh-Hans"),
          image: tagDoc.get("image"),
          created_at: tagDoc.get("created_at"),
        });
      }
    });
  },
  methods: {
    webpSrc(src: string) {
      return src.replace(/\/tour_tags\/thumb_/, "/tour_tags/thumbp_");
    },
  },
});
